import fetch from 'isomorphic-unfetch'
import { apiServer } from '../../lib/config'

export default async function fetchCompany(recordId: string, currentUserId?: string) {
  const response = await fetch(
    `${apiServer}/api/company/${recordId}${currentUserId ? `?userId=${currentUserId}` : ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.status === 200) {
    const { company } = await response.json()
    return { company }
  }
  console.warn('Company:', response)
  return {
    error: response,
  }
}
