import { User } from '../../model/User'
import { SomethingElse } from '../../lib/lib'

export const title = 'Interested in these roles'
export const key: keyof User = 'interestedRoles'

export const values = [
  "I'm up for anything",
  'Business Development / Sales',
  'Finance',
  'Growth / Marketing',
  'Operations / HR',
  'Product Management',
  'Project Management',
  'Strategy',
  'Engineering',
  SomethingElse,
]

export default { key, title, values }
