import React from 'react'
import Card from '@material-ui/core/Card'
import { UserType } from '../../lib/UserType'
import { User } from '../../model/User'
import { Company } from '../../model/Company'
import ProfileTags from '../Profile/ProfileTags'

interface Props {
  userType: UserType
  data: User | Company
}

const AccountPreferencesTags = (props: Props) => {
  const { userType, data } = props
  return (
    <>
      <Card variant="outlined">
        <ProfileTags userType={userType} data={data} />
      </Card>
    </>
  )
}

export default AccountPreferencesTags
