import { Company } from '../../model/Company'
import { SomethingElse } from '../../lib/lib'

export const title = 'Available Roles'
export const key: keyof Company = 'peopleLookingFor'

export const values = [
  'Business Development / Sales',
  'Finance',
  'Growth / Marketing',
  'Operations / HR',
  'Product Management',
  'Project Management',
  'Strategy',
  'Looking for smart people in general!',
  'Engineering',
  SomethingElse,
]

export default { key, title, values }
