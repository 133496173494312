import { User } from '../../model/User'
import { SomewhereElse } from '../../lib/lib'

export const title = 'Desired location'
export const key: keyof User = 'interestedLocations'

export const values = [
  'Boston',
  'Chicago',
  'DC',
  'LA',
  'London',
  'NYC',
  'San Diego',
  'Seattle',
  'SF / Bay Area',
  'Remote',
  SomewhereElse,
]

export default { key, title, values }
