import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  Button,
  Link,
  Chip as MUIChip,
  Grid,
  Tooltip,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { createSVIMarketingExternalLink } from '../../../lib/lib'
import { JobOpportunity, UpdateJobPostingReport } from '../../../model/JobOpportunity'
import {
  trackApplyJobOpportunity,
  trackClickReportJobPosting,
  trackJobOpportunityCopyLink,
  trackJobOpportunityView,
} from '../../../src/analytics'
import ReportProblemDialog from './ReportProblemDialog'
import { CurrentUser } from '../../../src/pageWrappers/withAuth'

type Props = {
  data: JobOpportunity[]
  selectedJobOpportunityId?: string
  copyLinkToJob?: (jobId: string) => void
  currentUser?: CurrentUser
  updateJobPostingReports?: UpdateJobPostingReport
}

const LocationChip = withStyles({
  root: {
    marginTop: '-2px',
    backgroundColor: 'rgba(0, 87, 255, 0.1)',
    height: '20px',
    fontWeight: 'normal',
  },
  label: {
    padding: '0 8px',
  },
})(MUIChip)

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: '#FAFAFA',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#FAFAFA',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: '36px',
    padding: '0 16px',
    '&$expanded': {
      minHeight: '36px',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails)

const JobOpportunitiesSection: React.FC<Props> = props => {
  const { data, selectedJobOpportunityId, currentUser, updateJobPostingReports } = props

  function getExpandedJob(): JobOpportunity | null {
    if (!data || !selectedJobOpportunityId) {
      return null
    }
    for (let i = 0; data.length > 0; i += 1) {
      if (data[i].id === selectedJobOpportunityId) {
        return data[i]
      }
    }
    return null
  }

  const [expanded, setExpanded] = useState(selectedJobOpportunityId ?? null)
  const [openReportProblem, setOpenReportProblem] = useState(false)
  const [expandedJob, setExpandedJob] = useState(getExpandedJob())

  useEffect(() => {
    if (!expandedJob) {
      return
    }

    trackJobOpportunityView(expandedJob.companyId[0], expandedJob.id)
  }, [(expandedJob || {}).id])

  const handleChange = (panel, job) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
    setOpenReportProblem(false)
    setExpandedJob(job)
  }

  function reportProblem() {
    setOpenReportProblem(!openReportProblem)
    if (!openReportProblem) {
      if (!expandedJob) {
        return
      }
      trackClickReportJobPosting(expandedJob.companyId[0], expandedJob.id)
    }
  }

  const copyLinkToJob = (jobId: string) => () => {
    const { copyLinkToJob } = props
    copyLinkToJob(jobId)
    trackJobOpportunityCopyLink(expandedJob.companyId[0], expandedJob.id)
  }

  function onApplyClick() {
    trackApplyJobOpportunity(expandedJob.companyId[0], expandedJob.id)
  }

  function getFormattedDate(text: string | Date): string | null {
    const date = new Date(text)
    if (Number.isNaN(date.getDate())) {
      return null
    }
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }

  if (!data || data.length === 0) return null

  return (
    <>
      {data.map(job => {
        const {
          title,
          description,
          url,
          id,
          location = [],
          reportedByCurrentUser,
          companyId,
          applicationDeadline,
        } = job
        const panelId = id
        return (
          <ExpansionPanel key={`key-${id}`} expanded={expanded === panelId} onChange={handleChange(panelId, job)}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${panelId}-content`}
              id={`${panelId}-header`}
            >
              <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
                <Grid item>
                  <Typography>
                    <Box fontWeight={500} fontSize={14}>
                      {title}
                    </Box>
                  </Typography>
                </Grid>
                {location.map((loc, index) => (
                  <Grid item key={`loc-tag-${index}`}>
                    <LocationChip label={loc} />
                  </Grid>
                ))}
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box>
                <Typography component="div">
                  <Box fontSize={15} whiteSpace="pre-line">
                    {description}
                  </Box>
                </Typography>
                {getFormattedDate(applicationDeadline) && (
                  <Typography component="div">
                    <Box mt={2} fontSize={14} color="text.secondary">
                      <Box display="inline" fontWeight="fontWeightBold">
                        Deadline:
                      </Box>{' '}
                      {getFormattedDate(applicationDeadline)}
                    </Box>
                  </Typography>
                )}
                <Box mx={1} my={1} display="flex" alignItems="center">
                  {!!props.copyLinkToJob && (
                    <Box flexGrow={1} mt={1}>
                      {url && (
                        <Link
                          href={createSVIMarketingExternalLink(url)}
                          target="_blank"
                          rel="noopener"
                          onClick={onApplyClick}
                        >
                          <Button>Apply</Button>
                        </Link>
                      )}
                      <Button onClick={copyLinkToJob(id)}>Copy link</Button>
                    </Box>
                  )}
                  {currentUser && (
                    <Box style={{ top: '5px' }}>
                      <Tooltip title="Report a problem with this job opening">
                        <IconButton>
                          <EmojiFlagsIcon onClick={reportProblem} titleAccess="Report a Problem" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                {openReportProblem && (
                  <ReportProblemDialog
                    startupId={companyId[0]}
                    jobId={id}
                    studentId={currentUser.recordId}
                    reportedByCurrentUser={reportedByCurrentUser}
                    updateJobPostingReports={updateJobPostingReports}
                  />
                )}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </>
  )
}

export default JobOpportunitiesSection
