import React, { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, Color } from '@material-ui/lab'
import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AccountPreferencesPopup from './AccountPreferencesPopup'
import { WithAuthProps } from '../../src/pageWrappers/withAuth'
import useCurrentUser from '../home/useCurrentUser'

interface Props extends WithAuthProps {
  onClose: () => void
}

const AccountPreferences = (props: Props) => {
  const {
    currentUser: { userType, recordId },
    onClose,
  } = props
  const [userData, userDataError] = useCurrentUser(userType, recordId)
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' })

  if (userDataError) {
    if (!snackbar.open) {
      setSnackbar({ open: true, message: userDataError.statusText, severity: 'error' })
    }
  }

  function close() {
    setSnackbar({ open: false, message: '', severity: 'warning' })
  }

  return (
    <>
      {!userDataError && <AccountPreferencesPopup data={userData} onClose={onClose} userType={userType} />}
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={close}>
          <Alert severity={snackbar.severity as Color}>{snackbar.message}</Alert>
        </Snackbar>
      )}
    </>
  )
}

export default AccountPreferences
