import React from 'react'
import { Chip, ChipProps } from '@material-ui/core'
import LanguageIcon from '@material-ui/icons/Language'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { orange, blue, grey } from '@material-ui/core/colors'
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { TagType } from '../../lib/TagType'
import { UserType } from '../../lib/UserType'

interface Props extends ChipProps {
  tagType?: TagType
  label: string
  userType: UserType
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yellow: {
      backgroundColor: 'rgb(255, 248, 227)',
    },
    blue: {
      backgroundColor: 'rgba(0, 87, 255, 0.1)',
    },
  }),
)

const StyledChip = withStyles({
  root: {
    // marginTop: '-2px',
    borderColor: 'transparent',
    // backgroundColor: 'rgba(0, 87, 255, 0.1)',
    // height: '20px',
    fontWeight: 'normal',
    margin: '4px 6px',
  },
  label: {
    padding: '0 8px',
  },
  outlined: {},
})(Chip)

const ProfileTagWithIcon = (props: Props) => {
  const { tagType, label, userType, ...rest } = props
  const classes = useStyles()
  const color = userType === UserType.Student ? orange[300] : blue[600]
  function getIcon() {
    if (!tagType) {
      return null
    }

    switch (tagType) {
      case TagType.InterestedLocations:
        return <LanguageIcon style={{ color }} />
      case TagType.CompanySize:
        return <PeopleOutlineIcon style={{ color }} />
      case TagType.PeopleLookingFor:
      case TagType.InterestedRoles:
        return <PersonAddIcon style={{ color }} />
      default:
        return null
    }
  }

  function tagColor() {
    switch (tagType) {
      case TagType.WhyWeAreSpecial:
      case TagType.OnMyWishList:
      case TagType.CompanySize:
      case TagType.InterestedSize:
        return classes.yellow
      case TagType.InterestedLocations:
      case TagType.Location:
      case TagType.Industry:
      case TagType.InterestedIndustries:
      case TagType.InterestedRoles:
      case TagType.PeopleLookingFor:
        return classes.blue
      default:
        return classes.blue
    }
  }

  return <StyledChip {...rest} classes={{ outlined: tagColor() }} icon={getIcon()} label={label} />
}

export default ProfileTagWithIcon
