import * as React from 'react'
import { useRouter } from 'next/router'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useState } from 'react'
import Logo from './logo'
import { trackLogoutEvent } from '../src/analytics'
import AccountPreferences from './AccountPreferences/AccountPreferences'

const useStyles = makeStyles(theme => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

interface Props {
  currentUser?: any
}

const AppBar: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [showPreferences, setShowPreferences] = useState(false)
  const router = useRouter()

  const handleLogout = () => {
    fetch('/api/account/logout', {
      method: 'POST',
    })
    localStorage.removeItem('currentUser')
    trackLogoutEvent()
    router.push('/login')
  }

  const handleMyAccount = () => setShowPreferences(true)

  const handleExplore = (): void => {
    router.push('/home')
  }

  function onClose() {
    setShowPreferences(false)
  }

  const { currentUser } = props

  return (
    <div className={classes.root}>
      <Box>
        {currentUser && (
          <Logo
            handleExplore={handleExplore}
            handleLogout={handleLogout}
            handleMyAccount={handleMyAccount}
            avatarUrl=""
          />
        )}
        {!currentUser && <Logo handleExplore={handleExplore} />}
      </Box>
      {showPreferences && <AccountPreferences currentUser={currentUser} onClose={onClose} />}
    </div>
  )
}

export default AppBar
