import React from 'react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import MuiAvatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import BusinessIcon from '@material-ui/icons/Business'
import { Company } from '../../model/Company'
import { User } from '../../model/User'
import { UserType } from '../../lib/UserType'
import { getApiUrlFile } from '../../lib/lib'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      objectFit: 'contain',
      height: theme.spacing(7),
      width: theme.spacing(7),
    },
  }),
)
interface Props {
  userType: UserType
  data: User | Company
}

type Info = {
  name: string
  avatar: {
    url?: string
  }
}

const AccountPreferencesTitle = (props: Props) => {
  const { userType, data } = props
  const classes = useStyles()

  function studentInfo(): Info {
    const { firstName, lastName, logo } = data as User
    const avatar = logo ? logo[0] : {}
    return { avatar, name: `${firstName} ${lastName}` }
  }

  function startupInfo(): Info {
    const { name, logo } = data as Company
    const avatar = logo ? logo[0] : {}
    return { avatar, name }
  }

  function getInfo() {
    if (userType === UserType.Student) {
      return studentInfo()
    }

    return startupInfo()
  }

  const info = getInfo()

  const fallbackAvatar = userType === UserType.Startup ? <BusinessIcon /> : null
  const avatarTitle = userType === UserType.Startup ? 'Logo' : 'Profile Picture'

  return (
    <Card variant="outlined">
      <CardContent>
        <Box my={2}>
          <Typography variant="caption" gutterBottom>
            Name:
          </Typography>
          <Typography variant="body1">{info.name}</Typography>
        </Box>

        <Divider />

        <Box my={2}>
          <Typography variant="caption" gutterBottom>
            {avatarTitle}:
          </Typography>
          <ListItemAvatar>
            <MuiAvatar
              alt={info.name}
              src={getApiUrlFile(info.avatar.url)}
              variant="square"
              classes={{ root: classes.avatar }}
            >
              {fallbackAvatar}
            </MuiAvatar>
          </ListItemAvatar>
        </Box>
      </CardContent>
    </Card>
  )
}

export default AccountPreferencesTitle
