import React, { useState } from 'react'
import { createStyles, Theme, withStyles, WithStyles, useTheme, makeStyles } from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Box, CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import AccountPreferencesTitle from './AccountPreferencesTitle'
import AccountPreferencesTags from './AccountPreferencesTags'
import AccountPreferencesSections from './AccountPreferencesSections'
import { UserType } from '../../lib/UserType'
import { Company } from '../../model/Company'
import { User } from '../../model/User'
import AccountPreferencesOpportunities from './AccountPreferencesOpportunities'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2, 3),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      objectFit: 'contain',
    },
    dialogActionsRoot: {
      padding: theme.spacing(1),
      display: 'block',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadingDialog: {
      height: 200,
    },
  }),
)

interface Props {
  onClose: () => void
  userType: UserType
  data: User | Company
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
  onClose: () => void
}

const AccountPreferencesPopup = (props: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    props.onClose()
  }

  const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const DialogActions = withStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(1),
      justifyContent: 'left',
    },
  }))(MuiDialogActions)

  const Dialog = withStyles((theme: Theme) => ({
    paper: {
      width: 600,
      maxWidth: 'sm',
    },
  }))(MuiDialog)

  const { userType, data } = props

  if (!data) {
    return (
      <Dialog fullScreen={fullScreen} open>
        <MuiDialogContent classes={{ root: classes.loadingDialog }}>
          <CircularProgress size={24} className={classes.buttonProgress} />
        </MuiDialogContent>
      </Dialog>
    )
  }

  return (
    <div>
      <Dialog fullScreen={fullScreen} onClose={handleClose} open>
        <DialogTitle onClose={handleClose}>
          <Typography variant="h6">Your profile</Typography>
          <Typography variant="subtitle1">
            To change your preferences and information, chat with us in the lower right corner.
          </Typography>
        </DialogTitle>
        <MuiDialogContent>
          <Box mb={2}>
            <AccountPreferencesTitle userType={userType} data={data} />
          </Box>
          <Box mb={2}>
            <AccountPreferencesTags userType={userType} data={data} />
          </Box>
          {userType === UserType.Startup && (
            <Box mb={2}>
              <AccountPreferencesOpportunities data={data as Company} />
            </Box>
          )}
          <Box mb={2}>
            <AccountPreferencesSections userType={userType} data={data} />
          </Box>
        </MuiDialogContent>
      </Dialog>
    </div>
  )
}

export default AccountPreferencesPopup
