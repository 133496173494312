import React from 'react'
import { createStyles, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ProfileTagWithIcon from './ProfileTagWithIcon'
import { TagCategory } from '../../model/Tag'
import { UserType } from '../../lib/UserType'

interface Props {
  title: string
  section: string
  tags: TagCategory[]
  userType: UserType
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      marginLeft: '-6px',
    },
  }),
)

const ProfileTagsRow = (props: Props) => {
  const classes = useStyles()
  const { title, tags, section, userType } = props

  return (
    <Box>
      <Typography variant="caption">{title}</Typography>
      <Box className={classes.box}>
        {tags.map((tag, index) => (
          <ProfileTagWithIcon
            variant="outlined"
            size="small"
            key={`tag-${section}-${index}`}
            label={tag.name}
            tagType={tag.category}
            userType={userType}
          />
        ))}
      </Box>
    </Box>
  )
}

export default ProfileTagsRow
