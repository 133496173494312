/* eslint-disable import/prefer-default-export */
export enum TagType {
  Industry = 'industry',
  PeopleLookingFor = 'peopleLookingFor',
  WhyWeAreSpecial = 'whyWeAreSpecial',
  CompanySize = 'companySize',
  InterestedIndustries = 'interestedIndustries',
  InterestedRoles = 'interestedRoles',
  InterestedLocations = 'interestedLocations', // Like To Be In
  Location = 'basedAt',
  School = 'school',
  InterestedSize = 'interestedSize',
  OnMyWishList = 'wishList',
}

// Because string enums don't provide reverse mapping out of the box
// aka (string) => UserType
// we supply it ourselves (https://stackoverflow.com/questions/54297376/getting-the-enum-key-with-the-value-string-reverse-mapping-in-typescript)
export function getTagTypeForString(value: string): TagType {
  const [key] = Object.keys(TagType).filter(x => TagType[x] === value)

  return TagType[key]
}
