import { useEffect, useState } from 'react'
import { UserType } from '../../lib/UserType'
import fetchUser from '../query/fetchStudent'
import fetchCompany from '../query/fetchCompany'

const useCurrentUser = (userType: UserType, recordId: string) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (userType === UserType.Student) {
      fetchUser(recordId).then(data => {
        if (data.error) {
          setError(data.error)
          return
        }

        setData(data.user)
      })
    } else {
      fetchCompany(recordId).then(data => {
        if (data.error) {
          setError(data.error)
          return
        }

        setData(data.company)
      })
    }
  }, [])

  return [data, error]
}

export default useCurrentUser
