import { NextApiRequest } from 'next'
import { DateAttributes, Followable, SviMember } from '../model/User'
import { tags } from '../tags/startup/addCategoreisTags'
import { Reportable } from '../model/JobOpportunity'
import { TagCategory } from '../model/Tag'
import { TagsSample } from '../components/Student/StudentTags'

export const upForAnything = "I'm up for anything"
export const smartPeople = 'Looking for smart people in general!'
export const OtherIndustry = 'Other Industry'
export const SomewhereElse = 'Somewhere else'
export const SomethingElse = 'Something else'
export const newJobPostDays = 7
const newJobPostPeriod = 1000 * 60 * 60 * 24 * newJobPostDays

export default function intersect(a: Array<string>, b: Array<string>) {
  const setA = new Set(a)
  const setB = new Set(b)
  const intersection = new Set([...setA].filter(x => setB.has(x)))
  return Array.from(intersection)
}

export function matchTags(src: string[], match: string[]): string[] {
  if (src.includes(upForAnything)) {
    return src
  }
  return intersect(src, match)
}

export function isExternalLink(url: string): boolean {
  const domainRegx = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i
  return domainRegx.test(url)
}

export function createExternalLink(url: string) {
  if (!url) return undefined

  if (isExternalLink(url)) {
    return url
  }

  return `https://${url}`
}

export const createSVIMarketingExternalLink = (url: string) => {
  if (!url) return undefined

  const externalLink = createExternalLink(url)
  const utmSourceParam = 'utm_source=svi.io%20%2F%20Need%20Eng%3F%20info%40svi.io'

  if (externalLink.includes('?')) {
    return `${externalLink}&${utmSourceParam}`
  }

  return `${externalLink}?${utmSourceParam}`
}

export function uniqTags(tags, key) {
  const uniq = tags.reduce((acc, value) => {
    if (!acc[value[key]]) {
      acc[value[key]] = value
    }
    return acc
  }, {})
  return Object.values(uniq)
}

export function filterSVIUsers<T extends SviMember>(req: NextApiRequest, user: T): boolean {
  if (req.query.showSviUsers) {
    return true
  }

  return !user.sviMember
}

export function addDateAttributes<T extends DateAttributes>(user: T): T {
  const { createdAt: strCreatedAt, modifiedAt: strModifiedAt } = user
  const attrs = {} as any

  if (strCreatedAt) {
    attrs.createdAt = new Date(strCreatedAt).valueOf()
  }

  if (strModifiedAt) {
    attrs.modifiedAt = new Date(strModifiedAt).valueOf()
  }

  return { ...user, ...attrs }
}

export function addFollowedByCurrentUser<T extends Followable>(req: NextApiRequest, user: T): T {
  const userId = req.query.userId as string
  const followedByCurrentUser = !!(user.followers && user.followers.includes(userId))
  return { ...user, followedByCurrentUser }
}

export function addReportedByCurrentUser<T extends Reportable>(req: NextApiRequest, data: T): T {
  const userId = req.query.userId as string
  const reportedByCurrentUser = !!(data.reports && data.reports.includes(userId))
  return { ...data, reportedByCurrentUser }
}

export function addNewJobPostingFlag<T extends DateAttributes>(data: T): T {
  const isNew = new Date().valueOf() - new Date(data.createdAt).valueOf() < newJobPostPeriod
  return { ...data, isNew }
}

export function isTextNotEmpty(text?: string): boolean {
  if (!text || typeof text !== 'string') {
    return false
  }

  return text.trim().length > 0
}
export function objectFlip(obj) {
  const res = {}
  Object.keys(obj).forEach(key => {
    res[obj[key]] = key
  })
  return res
}

export function sampleRolesIndustriesTags(roles: TagCategory[], industries: TagCategory[]): TagsSample {
  const roleUpForAnything = roles.filter(({ name }) => name === upForAnything)
  const industryUpForAnything = industries.filter(({ name }) => name === upForAnything)

  if (roleUpForAnything.length && industryUpForAnything.length) {
    return { sample: roleUpForAnything, rest: [] }
  }

  if (roleUpForAnything.length) {
    const [industry, ...restIndustries] = industries
    return { sample: roleUpForAnything.concat(industry).filter(Boolean), rest: [...restIndustries] }
  }

  if (industryUpForAnything.length) {
    const [role, ...restRoles] = roles
    return { sample: industryUpForAnything.concat(role).filter(Boolean), rest: [...restRoles] }
  }

  if (roles.length >= 1 && industries.length >= 1) {
    const [role, ...restRoles] = roles
    const [industry, ...restIndustries] = industries
    return { sample: [role, industry], rest: [...restRoles, ...restIndustries] }
  }
  if (roles.length) {
    const [role1, role2, ...restRoles] = roles
    return { sample: [role1, role2].filter(Boolean), rest: [...restRoles] }
  }
  if (industries.length) {
    const [industry1, industry2, ...restIndustries] = industries
    return { sample: [industry1, industry2].filter(Boolean), rest: [...restIndustries] }
  }
  return { sample: [], rest: [] }
}

export function getApiUrlFile(url: string): string | null {
  // DEBUG: Log the url pulled
  console.log("URL Pulled " + url)
  if (!url) {
    return null
  }

  // TODO: This is a hack to fix URLs
  return url

  const [_, path] = url.split('.attachments/')
  if (path) {
    // DEBUG: Logo if path
    console.log("URL path" + `/api/files/${path}`)

    return `/api/files/${path}`
  }

  const [__, pathThumbnails] = url.split('.attachmentThumbnails/')
  if (pathThumbnails) {
    // DEBUG: Logo if path
    console.log("URL thumbnails" + `//api/thumbnails/${pathThumbnails}`)

    return `/api/thumbnails/${pathThumbnails}`
  }
  return null
}
