import React, { useState } from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckIcon from '@material-ui/icons/Check'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import reportJob from '../../query/reportJob'
import { UpdateJobPostingReport } from '../../../model/JobOpportunity'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    pillContainer: {
      flexGrow: 1,
    },
  }),
)

interface Props {
  studentId: string
  jobId: string
  startupId: string
  reportedByCurrentUser?: boolean
  updateJobPostingReports?: UpdateJobPostingReport
}

enum Option {
  Outdated,
  NoLongerHiring,
  Other,
}

const ReportProblemDialog = (props: Props) => {
  const { startupId, studentId, jobId, reportedByCurrentUser, updateJobPostingReports } = props
  const classes = useStyles()

  const outdated = 'This posting is outdated'
  const noLongerHiring = 'Company is no longer hiring'

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [reported, setReported] = useState(reportedByCurrentUser)
  const [error, setError] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option>()
  const [message, setMessage] = useState<string>()

  const onClickPredefined = (option: Option, text?: string) => () => {
    setSelectedOption(option)
    setSuccess(false)
    if (option !== Option.Other) {
      setMessage(text)
    }
  }

  function onEnterText(e) {
    setMessage(e.target.value)
  }

  async function onReport() {
    setError(false)
    if (!loading) {
      setSuccess(false)
      setLoading(true)
      try {
        const result = await reportJob({ Student: [studentId], 'Job Posting': [jobId], Content: message })
        setReported(true)
        setLoading(false)
        setSuccess(true)
        if (typeof updateJobPostingReports === 'function') {
          updateJobPostingReports(startupId, jobId, studentId)
        }
      } catch (e) {
        setReported(false)
        setLoading(false)
        setError(true)
      }
    }
  }

  function getVariant(option: Option): 'default' | 'outlined' {
    return option === selectedOption ? 'default' : 'outlined'
  }

  const btnIcon = success ? <CheckIcon /> : null

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })

  function content() {
    if (reported) {
      return (
        <Paper variant="outlined">
          <Box p={1}>
            <Typography variant="subtitle2" color="secondary">
              Thanks for flagging this. We&apos;ve received your request and will be in touch soon!
            </Typography>
          </Box>
        </Paper>
      )
    }
    if (success) {
      return (
        <Paper variant="outlined">
          <Box p={1}>
            <Typography variant="subtitle2" color="secondary">
              Thanks for flagging this. We&apos;ve received your request and will be in touch soon!
            </Typography>
          </Box>
        </Paper>
      )
    }
    return (
      <Box>
        <Grid container className={classes.pillContainer} direction="row" spacing={1}>
          <Grid item>
            <Chip
              variant={getVariant(Option.Outdated)}
              size="small"
              label={outdated}
              onClick={onClickPredefined(Option.Outdated, outdated)}
            />
          </Grid>
          <Grid item>
            <Chip
              variant={getVariant(Option.NoLongerHiring)}
              size="small"
              label={noLongerHiring}
              onClick={onClickPredefined(Option.NoLongerHiring, noLongerHiring)}
            />
          </Grid>
          <Grid item>
            <Chip
              variant={getVariant(Option.Other)}
              size="small"
              label="Other"
              onClick={onClickPredefined(Option.Other)}
            />
          </Grid>
        </Grid>
        <Box display={selectedOption === Option.Other ? 'block' : 'none'} mt={1}>
          <TextField
            fullWidth
            label="Message to report"
            placeholder="Write your message"
            multiline
            rows="3"
            defaultValue=""
            onChange={onEnterText}
          />
        </Box>
        <Box mt={1} display="flex">
          <div className={classes.wrapper}>
            <Button
              startIcon={btnIcon}
              variant="contained"
              className={buttonClassname}
              color="primary"
              disabled={selectedOption === undefined || loading}
              onClick={onReport}
            >
              Report
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Box>
        {error && (
          <Paper variant="outlined">
            <Box p={1}>
              <Typography variant="subtitle2" color="error">
                There was a problem to report job posting. Try to send it again or please message us using the chat
                widget to resolve this issue.
              </Typography>
            </Box>
          </Paper>
        )}
      </Box>
    )
  }

  return content()
}

export default ReportProblemDialog
