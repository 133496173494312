import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Company } from '../../model/Company'
import JobOpportunitiesSection from '../Profile/startup/JobOpportunities'

interface Props {
  data: Company
}

const AccountPreferencesOpportunities = (props: Props) => {
  const {
    data: { jobOpportunities },
  } = props
  return (
    <>
      <Typography variant="caption" gutterBottom>
        Job Opportunities:
      </Typography>
      <JobOpportunitiesSection data={jobOpportunities} />
    </>
  )
}

export default AccountPreferencesOpportunities
