import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Avatar, Menu, MenuItem, Link } from '@material-ui/core'
import NextLink from 'next/link'

interface Props {
  handleLogout?: () => void
  handleMyAccount?: () => void
  handleExplore: () => void
  avatarUrl?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '20px 32px',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 25px',
      },
    },
    logo: {
      width: '48px',
      '&:hover': {
        opacity: 0.5,
      },
    },
    wrapper: {
      position: 'relative',
    },
    avatar: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '32px',
      height: '32px',
      marginTop: -19,
      marginLeft: -8,
      backgroundColor: '#4478e2',
      backgroundBlendMode: 'multiply',
      opacity: 0.6,
      pointerEvents: 'none',
    },
    profileLink: {
      cursor: 'pointer',
    },
    menu: {
      marginTop: '40px',
    },
    link: {
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: 'DM Sans',
      color: '#001774',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
)

const Logo = (props: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { handleLogout, handleMyAccount } = props

  const displayUser = handleLogout && handleMyAccount

  const handleOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (displayUser) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logoutHandler = () => {
    props.handleLogout()
    setAnchorEl(null)
  }

  const myAccountHandler = () => {
    props.handleMyAccount()
    setAnchorEl(null)
  }

  const exploreHandler = () => {
    props.handleExplore()
    setAnchorEl(null)
  }

  const { avatarUrl } = props

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box aria-haspopup="true" aria-controls="simple-menu">
          {displayUser && (
            // eslint-disable-next-line
            <a className={classes.profileLink} onClick={handleOpen}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img className={classes.logo} src="/logo.png" />
              <Avatar className={classes.avatar} alt="Remy Sharp" src={avatarUrl ?? ''} />
            </a>
          )}
          {!displayUser && (
            <NextLink href="/home" passHref>
              {/* eslint-disable-next-line */}
              <a>
                {/* eslint-disable-next-line */}
                <img className={classes.logo} src="/logo.png" />
              </a>
            </NextLink>
          )}
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem onClick={exploreHandler}>Explore</MenuItem>
          <MenuItem onClick={myAccountHandler}>My account</MenuItem>
          <MenuItem onClick={logoutHandler}>Logout</MenuItem>
        </Menu>
      </Box>
      <Box ml={2}>
        <NextLink href="/home" passHref>
          {/* eslint-disable-next-line */}
          <Link className={classes.link}>Hire a Startup MBA</Link>
        </NextLink>
      </Box>
    </Box>
  )
}

export default Logo
