import { User } from '../../model/User'
import { OtherIndustry } from '../../lib/lib'

export const title = 'Interested in these industries'
export const key: keyof User = 'interestedIndustries'

export const values = [
  "I'm up for anything",
  '3D Printing',
  'Analytics',
  'Artificial Intelligence',
  'Baby/Kids/Parenting',
  'Biotechnology',
  'Bitcoin / Cryptocurrency',
  'Blockchain',
  'Computer Vision',
  'Consumer Goods/Services',
  'E-Commerce',
  'Education',
  'Entertainment',
  'Fashion/Apparel',
  'FinTech',
  'Healthcare',
  'Hospitality',
  'Incubators',
  'Internet of Things',
  'Logistics',
  'Machine Learning',
  'Marijuana',
  'Marketplaces',
  'Real Estate',
  'Search Fund',
  'Software',
  'VC',
  'Virtual Reality',
  OtherIndustry,
]

export default { key, title, values }
