import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { Company } from '../../model/Company'
import { User } from '../../model/User'
import { UserType } from '../../lib/UserType'

interface Props {
  userType: UserType
  data: User | Company
}

const AccountPreferencesSections = (props: Props) => {
  const { userType, data } = props

  function studentInfo() {
    const { email, linkedIn, school, graduationYear, awesomeThing, topThingToGetOut, mainThingCanAdd } = data as User
    return [
      {
        label: 'Email',
        value: email,
      },
      {
        label: 'LinkedIn',
        value: linkedIn,
      },
      {
        label: 'School',
        value: school,
      },
      {
        label: 'Graduation Year',
        value: graduationYear,
      },
      {
        label: 'One awesome thing I’ve accomplished',
        value: awesomeThing,
      },
      {
        label: 'The top thing that I want to get out of this summer',
        value: topThingToGetOut,
      },
      {
        label: 'What I can add to a startup',
        value: mainThingCanAdd,
      },
    ]
  }

  function startupInfo() {
    const { jobPostings, website, contactName, careersOfficialEmail, about, shortBlurb, awesomeThing } = data as Company
    return [
      {
        label: 'Website',
        value: website,
      },
      {
        label: 'Contact Name',
        value: contactName,
      },
      {
        label: 'Contact email',
        value: careersOfficialEmail,
      },
      {
        label: 'Careers Site',
        value: jobPostings,
      },
      {
        label: 'About',
        value: about,
      },
      {
        label: 'Short text',
        value: shortBlurb,
      },
    ]
  }

  function getInfo() {
    if (userType === UserType.Student) {
      return studentInfo()
    }

    return startupInfo()
  }

  const info = getInfo()

  return (
    <div>
      <Card variant="outlined">
        <CardContent>
          {info.map((item, index) => (
            <div key={`preference-sections-${index}`}>
              <Box my={2}>
                <Typography variant="caption" gutterBottom>
                  {item.label}
                </Typography>
                <Typography variant="body1">{item.value || <i>Not set</i>}</Typography>
              </Box>
              <Divider />
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  )
}

export default AccountPreferencesSections
