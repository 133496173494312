import { Company } from '../../model/Company'
import { SomewhereElse } from '../../lib/lib'

export const title = 'Location'
export const key: keyof Company = 'basedAt'

export const values = [
  'Boston',
  'Chicago',
  'DC',
  'LA',
  'London',
  'NYC',
  'San Diego',
  'Seattle',
  'SF / Bay Area',
  'Remote',
  SomewhereElse,
]

export default { key, title, values }
