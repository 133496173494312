import { Box, createStyles, Theme } from '@material-ui/core'
import React from 'react'
import { UserType } from '../../lib/UserType'
import Industry from '../../tags/startup/Industry'
import PeopleLookingFor from '../../tags/startup/PeopleLookingFor'
import WhyWeAreSpecial from '../../tags/startup/WhyWeAreSpecial'
import InterestedIndustries from '../../tags/student/InterestedIndustries'
import InterestedRoles from '../../tags/student/InterestedRoles'
import LikeToBeIn from '../../tags/student/LikeToBeIn'
import ProfileTagsRow from './ProfileTagsRow'
import StartupSize from '../../tags/startup/StartupSize'
import StartupLocation from '../../tags/startup/Location'
import InterestedSize from '../../tags/student/StartupSize'
import OnMyWishList from '../../tags/student/OnMyWishList'
import { upForAnything } from '../../lib/lib'

interface Props {
  userType: UserType
  data: any
}

const StartupTags = [
  {
    title: Industry.title,
    key: Industry.key,
  },
  {
    title: PeopleLookingFor.title,
    key: PeopleLookingFor.key,
  },
  {
    title: WhyWeAreSpecial.title,
    key: WhyWeAreSpecial.key,
  },
  {
    title: StartupLocation.title,
    key: StartupLocation.key,
  },
  {
    title: StartupSize.title,
    key: StartupSize.key,
  },
]

const StudentTags = [
  {
    title: InterestedIndustries.title,
    key: InterestedIndustries.key,
  },
  {
    title: InterestedRoles.title,
    key: InterestedRoles.key,
  },
  {
    title: OnMyWishList.title,
    key: OnMyWishList.key,
  },
  {
    title: LikeToBeIn.title,
    key: LikeToBeIn.key,
  },
  {
    title: InterestedSize.title,
    key: InterestedSize.key,
  },
]

const ProfileTags = (props: Props) => {
  const {
    userType,
    data: { tagsCategories: data },
  } = props

  function studentTagsData() {
    return StudentTags.filter(item => {
      return data[item.key] && data[item.key].length
    }).map(item => {
      const names = data[item.key].map(({ name }) => name)
      if (names.includes(upForAnything)) {
        return { ...item, ...{ tags: data[item.key].filter(({ name }) => name === upForAnything) } }
      }
      return { ...item, ...{ tags: data[item.key] } }
    })
  }

  function startupTagsData() {
    return StartupTags.filter(item => {
      return data[item.key] && data[item.key].length
    }).map(item => {
      return { ...item, ...{ tags: data[item.key] } }
    })
  }

  function studentTags() {
    return (
      <Box mx={2}>
        {studentTagsData().map((tags, index) => (
          <ProfileTagsRow
            userType={userType}
            {...tags}
            key={`tags-${tags.key}-${index}`}
            section={`tags-${tags.key}-${index}`}
          />
        ))}
      </Box>
    )
  }

  function startupTags() {
    return (
      <Box mx={2}>
        {startupTagsData().map((tags, index) => (
          <ProfileTagsRow
            userType={userType}
            {...tags}
            key={`tags-${tags.key}-${index}`}
            section={`tags-${tags.key}-${index}`}
          />
        ))}
      </Box>
    )
  }

  if (userType === UserType.Student) {
    return studentTags()
  }

  return startupTags()
}

export default ProfileTags
