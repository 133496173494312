import fetch from 'isomorphic-unfetch'
import { apiServer } from '../../lib/config'

export default async function fetchUser(recordId: string) {
  const response = await fetch(`${apiServer}/api/user/${recordId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (response.status === 200) {
    const { user } = await response.json()
    return { user }
  }
  console.warn('Student:', response)
  return {
    error: response,
  }
}
