import fetch from 'isomorphic-unfetch'
import { apiServer } from '../../lib/config'

export type ReportJob = {
  Content: string
  'Job Posting': string[]
  Student: string[]
}

export default async function reportJob(data: ReportJob) {
  const response = await fetch(`${apiServer}/api/report/job`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data }),
  })
  if (!response.ok) throw new Error(response.statusText)

  await response.json()
}
